<template>
  <!-- eslint-disable -->
  <a-popover placement="rightTop">
    <template #title>
      <span>Time Zone</span>
    </template>
    <template #content>
      <div class="c-hex-52c41a f-s-12">
        国际协调时间:
        <time :datetime="defUnix(time, 0, 'UTC')" class="c-hex-2f54eb">
          {{ defElse(time, 0, 'UTC') }}
        </time>
      </div>
      <div class="def-line" />
      <div class="c-hex-f5222d f-s-12 c-hex-8c8c8c">
        中国(东八区):
        <time :datetime="defUnix(time, 8, 'UTC/GMT+8')" class="c-hex-2f54eb">
          {{ defElse(time, 8, 'UTC/GMT+8') }}
        </time>
      </div>
      <div class="def-line" />
      <div class="f-s-12">美国:</div>
      <ul class="tpl12">
        <li v-for="i of usatzlist" :key="i.enabb" class="f-s-12">
          {{ i.title }}
          <span class="c-hex-8c8c8c">
            <time class="c-hex-2f54eb" :datetime="defUnix(time, WinterAndSummerTime(i.tz, time) ? i.s_tz : i.tz, WinterAndSummerTime(i.tz, time) ? i.s_enabb : i.enabb)">
              {{ defElse(time, WinterAndSummerTime(i.tz, time) ? i.s_tz : i.tz, WinterAndSummerTime(i.tz, time) ? i.s_enabb : i.enabb) }}
            </time>
          </span>
        </li>
      </ul>
    </template>
    <time
      style="text-decoration: underline; text-underline-offset: 3px; text-decoration-color: #d9d9d9"
      :datetime="defUnix(time, WinterAndSummerTime(-8, time) ? -7 : -8, WinterAndSummerTime(-8, time) ? 'PDT' : 'PST')"
      class="cp"
    >
      {{ defElse(time, WinterAndSummerTime(-8, time) ? -7 : -8, WinterAndSummerTime(-8, time) ? 'PDT' : 'PST') }}
    </time>
  </a-popover>
</template>

<script setup>
  /**
   * author: ღ
   * date: 2023/2/16
   */

  /* eslint-disable */

  import { ref } from 'vue'
  import moment from 'moment'
  import WinterAndSummerTime from '@/utils/WinterAndSummerTime'

  const _moment = moment

  const dateTime = ref(new Date().getTime())

  defineProps({
    time: {
      type: String, // UTC/GMT+08:00 北京时间
      default: ''
    }
  })

  const usatzlist = ref([
    {
      title: '东部(西五区):',
      tz: -5,
      s_tz: -4,
      enabb: 'EST',
      s_enabb: 'EDT'
    },
    {
      title: '中部(西六区):',
      tz: -6,
      s_tz: -5,
      enabb: 'CST',
      s_enabb: 'CDT'
    },
    {
      title: '山地(西七区):',
      tz: -7,
      s_tz: -6,
      enabb: 'MST',
      s_enabb: 'MDT'
    },
    {
      title: '太平洋(西八区):',
      tz: -8,
      s_tz: -7,
      enabb: 'PST',
      s_enabb: 'PDT'
    },
    {
      title: '阿拉斯加(西九区):',
      tz: -9,
      s_tz: -8,
      enabb: 'AKST',
      s_enabb: 'AKDT'
    },
    {
      title: '夏威夷(西十区):',
      tz: -10,
      s_tz: -10,
      enabb: 'HST',
      s_enabb: 'HST'
    },
    {
      title: '印第安纳州:',
      tz: -5,
      s_tz: -5,
      enabb: 'EST',
      s_enabb: 'EST'
    },
    {
      title: '亚利桑那州:',
      tz: -7,
      s_tz: -7,
      enabb: 'MST',
      s_enabb: 'MST'
    }
  ])

  function defElse(_time, _utcOffset, z = '') {
    if (!_time) return ''
    return (
      _moment
        .parseZone(_time + '+00:00')
        .utc()
        .utcOffset(_utcOffset)
        .format(`YYYY-MM-DD HH:mm:ss.SSS`) +
      ' ' +
      z
    )
  }

  function defUnix(_time, _utcOffset, z = '') {
    if (!_time) return ''
    return _moment
      .parseZone(_time + '+00:00')
      .utc()
      .utcOffset(_utcOffset)
      .format(`x`) // Unix 毫秒时间戳
  }

  // UTC 国际时 0
  // UTC/GMT+08:00 北京时间 8
  // PST是太平洋标准时间（西八区区时）PST是太平洋标准时间（西八区区时），
  // 与北京时间（东八区区时）时差16个小时（冬令时）或15个小时（夏令时(日光节约时制)）。
  // 时差的计算方法：两个时区标准时间（即时区数）相减就是时差，时区的数值大的时间早，PST和北京时间的时差计算方式为：（东八区区时）-（西八区区时）=（UTC+8）-（UTC-8）=16小时。
  // 2007年起延长夏令时间，从每年3月的第二个星期日开始，至每年11月的第一个星期日结束，夏令时间人为将时间调快一小时，PST时区与北京的时间的时差为16-1=15小时。
  // 美国横跨西五区至西十区，共六个时区。每个时区对应一个标准时间，从东向西分别为
  // 东部时间(EST)(西五区时间) -5
  // 中部时间(CST)(西六区时间) -6
  // 山地时间(MST)(西七区时间) -7
  // 太平洋时间(西部时间)(PST)(西八区时间) -8
  // 阿拉斯加时间(AKST)(西九区时间) -9
  // 夏威夷时间(HST)(西十区时间) -10

  //     美国自东海岸至西海岸横跨西五区至西十区，共六个时区。
  // 一、东部时间——EASTERN TIME（ET） (西五区时间) 华盛顿特区、纽约、波士顿、亚特兰大、费城、迈阿密等在此时区。
  // 二、中部时间——CENTRAL TIME（CT） (西六区时间) 芝加哥、圣路易斯、新奥尔良、休斯顿等在此时区。
  // 三、山地时间——MOUNTAIN TIME（MT） (西七区时间) 丹佛、凤凰城、盐湖城等在此时区。
  // 四、太平洋时间——pacific time zone（PST）(西八区时间) 洛杉矶、旧金山、圣地亚哥、拉斯维加斯、西雅图等在此时区。
  // 五、阿拉斯加-夏威夷时间——Alaska-Hawaii time (西九区时间和西十区时间) 阿拉斯加和夏威夷等在此时区。
  //     中美标准时差，即美国标准时间与北京时间的时差：
  // 1、东部时间：-13
  //     2、中部时间：-14
  //     3、山地时间：-15
  //     4、太平洋时间：-16
  //     5、阿拉斯加-夏威夷时间：-17、-18
  //     美国夏令时
  //     中美夏令时时差，即美国夏令时间与北京时间的时差： （夏令时比标准时间早一个小时）
  // 1、东部时间：-12
  //     2、中部时间：-13
  //     3、山地时间：-14
  //     4、太平洋时间：-15
  //     5、阿拉斯加-夏威夷时间：-16、-17
  //     美国时区简称：东部(EST/EDT)，中部(CST/CDT)，山地(MST/MDT)，太平洋(PST/PDT)，阿拉斯加(AKST/AKDT)，夏威夷(HST/HDT)
  //     ST是Standard Time（标准时间）；DT是Daylight Time（夏令时间）。

  // 而美国采用夏冬双时制，一个是夏令时，一个是冬令时（即标准时）。
  // 夏令时比标准时早一个小时。
  // 美国从2007年起修正时令，夏令时在每年三月的第二个星期日，冬令时在每年十一月的第一个星期日。亚利桑那州、印第安娜州、夏威夷州不使用夏时制。
</script>

<style lang="less" scoped>
  .cp {
    cursor: pointer;
  }
  .c-hex-f5222d {
    /*color: #f5222d;*/
    color: rgba(0, 0, 0, 0.85);
  }

  .c-hex-fa541c {
    /*color: #fa541c;*/
    color: rgba(0, 0, 0, 0.85);
  }

  .c-hex-2f54eb {
    /*color: #2f54eb;*/
    color: rgba(0, 0, 0, 0.85) !important;
  }

  .c-hex-52c41a {
    /*color: #52c41a;*/
    color: rgba(0, 0, 0, 0.85);
  }

  .c-hex-8c8c8c {
    color: #8c8c8c;
  }

  .def-line {
    margin: 4px 0in;
    border-top: 0.5px solid #d9d9d9;
  }
  .tpl12 {
    padding-left: 12px;
  }

  .f-s-12 {
    font-size: 12px;
    &:extend(.c-hex-8c8c8c);
  }
</style>
