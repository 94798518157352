export default {
  path: '/supplier-system/financialManagement',
  name: 'financialManagement',
  orderBy: 2,
  meta: {
    title: '财务中心',
  },
  component: () => import('@/views/home'),
  hidden: true,
  redirect: '/supplier-system/financialManagement/paymentDeductBill',
  children: [
    {
      path: 'paymentDeductBill',
      name: 'paymentDeductBill',
      meta: {
        title: '补扣款单',
        icon: 'fa fa-usd',
        keepAlive: false,
        beforeEnter: true,
      },
      hidden: true,
      component: () => import('@/views/supplier/financialManagement/paymentDeductBill/paymentDeductBill'),
    },
  ],
};
