module.exports = {
  language: {
    name: 'English',
    short: 'zh_CN'
  },
  logoName: '商家端',
  asin: '输入asin/spu',
  selectAnchor: '选择主播',
  selectBrand: '选择品牌',
  chinaTime: '中国时间',
  localTime: '当地时间',
  localData: '当地日期',
  search: '查询',
  reset: '重置',
  typeLive: '直播中',
  endLive: '已结束',
  notStartLive: '未开始',
  watchLive: '观看',
  selectShop: '选择店铺',
  selectMonth: '选择月份',
  brokerage: '佣金',
  affirm: '确认',
  confirm: '确定',
  cancel: '取消',
  confirmed: '已确认',
  download: '下载',
  particulars: '明细',
  account: '账户可用余额（美元）',
  popconfirmMessage: '确认，即表示认可结算金额，不可再做更改，现在确认么？',
  languMsg: '请选择语言',
  calendar: {
    numLives: '本月已创建10场直播',
    lived: '已直播10场',
    session: '总场次10场',
    sessioning: '已播场次10场',
    shopNums: '商品数10件'
  },
  setting: {
    crmSetting: '系统设置',
    password: '密码',
    languageType: '首选语言',
    updatePassword: '修改密码',
    save: '保存',
    quitLogin: '退出登录',
    quitMsg: '请确认是否要退出当前登录',
    platform: '选择系统',
    amazon: '亚马逊商家',
    wahool: '服饰供应商',
    enter: '进入',
    serviceSystem: '服务系统',
    serviceSystem1: '营销服务系统',
    serviceSystem2: '分销服务系统'
  },
  paswordSet: {
    oldInputMsg: '请输入新密码',
    newInputMsg: '请再次输入新密码',
    verifyOldMsg: '请输入密码',
    verifyNewMsg: '请输入确认密码',
    oldNewOvifyMsg: '两次密码不一致'
  },
  login: {
    usernameLabel: '用户名',
    passwordLabel: '密码',
    userNamePla: `Test{'@'}****公司`,
    userNamePlas: '请输入您的账号',
    passwordPla: '请输入您的密码',
    login: '登录',
    amazonLogin: '登录并授权',
    amazonLoginTitle: '授权您的 Amazon Store',
    ConfirmBinding: '确认授权',
    logged: '已登录用户',
    logInOther: '登录其他账号',
    selectStoreTitle: '选择您要授权的店铺',
    selectedText: '已选',
    selectedNum: '个店铺',
    modalCancel: '取消',
    modalConfirm: '确认'
  },
  tablement: {
    month: '月份',
    settlement: '结算周期',
    mentType: '结算类别',
    shopName: '店铺',
    sellNums: '销售件数',
    sellPrice: '销售金额',
    returnNums: '退货件数',
    returnPrice: '退货金额',
    settlementPrice: '结算金额',
    operate: '操作'
  },
  detTab1: {
    merchant: '商家',
    shop: '店铺',
    localData: '当地日期',
    localTime: '当地时间',
    livelink: '直播链接',
    pit: '坑位',
    pitPrice: '坑位费',
    brand: '品牌/ASIN',
    clickrate: '点击率'
  },
  chargeTable: {
    enterPrice: '入账日期',
    accountType: '结算类别',
    accountCycle: '结算周期',
    remitPrice: '打款金额($)',
    entryPrice: '入帐金额($)',
    expendPrice: '支出金额($)',
    subtotalPrice: '小计($)'
  },
  detTab2: {
    month: '月份',
    dataTime: '日期',
    shop: '店铺',
    brand: '品牌',
    commissionType: '佣金%',
    commission: '佣金'
  },
  Amazon: {
    ConnectDate: '授权日期',
    authorizedTitle: '授权您的 Amazon Store',
    authorizedDescription: '将您在Wahool的销售订单自动同步到您的 Amazon Store 并完成订单履约',
    connectBtn: '开始授权',
    settings: '设置',
    Remove: '移除授权',
    ReAuthorize: '重新授权',
    EXPIRE: '您的店铺授权已过期,请"重新授权"您的Amazon店铺!以便 Wahool 可以继续同步订单信息到您的 Amazon店铺。',
    ABOUTTOEXPIRE: '您的店铺授权即将过期,请 "重新授权"您的Amazon店铺！以便 Wahool 可以继续同步订单信息到您的 Amazon店铺。',
    stores: '店铺',
    storeSetting: '店铺设置',
    orderOpaction: '订单履约操作',
    orderShipMess: '订单运输方式',
    placeOrderRemaekMess: '请输入备注信息',
    orderRemarkMess: '订单备注信息',
    settingMess: '完成设置 / 保存',
    placeSelectOpac: '请选择订单履约操作',
    placeSelectShip: '请选择订单运输方式',
    addNewStore: '添加新店铺',
    noSettingMess: '店铺未启用，请前完成店铺设置',
    renewMess: '店铺授权即将过期',
    authorizationExpired: '店铺授权已过期',
    authorizationFailedErr: '店铺授权失败',
    authorizationBinding: '亚马逊店铺已经绑定商家',
    authorizationRetry: '请先移除原商家授权后重试',
    closeBtn: '关闭'
  },
  route: {
    live: {
      live: '直播',
      liveCalendar: '排期日历',
      LiveList: '排期列表'
    },
    financial: {
      financial: '财务',
      settlement: '结算单',
      charge: '充值余额',
      financialDetail: '结算单明细'
    },
    integrated: {
      integrated: '集成',
      Amazon: 'Amazon',
      Settings: 'Settings'
    }
  }
}
