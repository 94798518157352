export default {
  path: '/supplier-system/orderManagement',
  name: 'orderManagement',
  orderBy: 0,
  meta: {
    title: '订单中心',
  },
  component: () => import('@/views/home'),
  hidden: true,
  redirect: '/supplier-system/orderManagement/orderManage',
  children: [
    {
      path: 'orderManage',
      name: 'orderManage',
      meta: {
        title: '订单列表',
        icon: 'fa fa-credit-card',
        keepAlive: true,
        beforeEnter: true,
      },
      hidden: true,
      component: () => import('@/views/supplier/orderManagement/orderManage/orderManage'),
    },
    {
      path: 'orderManageDetail',
      name: 'orderManageDetail',
      meta: {
        title: '订单详情',
        icon: 'fa fa-usd',
        keepAlive: false,
        beforeEnter: true,
      },
      hidden: false,
      component: () => import('@/views/supplier/orderManagement/orderManage/orderManageDetail'),
    },
    {
      path: 'shippingDetails',
      name: 'shippingDetails',
      meta: {
        title: '一键发货详情',
        icon: 'fa fa-usd',
        keepAlive: false,
        beforeEnter: true,
      },
      hidden: false,
      component: () => import('@/views/supplier/orderManagement/orderManage/shippingDetails'),
    },
    {
      path: 'deliveryManage',
      name: 'deliveryManage',
      meta: {
        title: '发货列表',
        icon: 'fa fa-truck',
        keepAlive: true,
        beforeEnter: true,
      },
      hidden: true,
      component: () => import('@/views/supplier/orderManagement/deliveryManage/deliveryManage'),
    },
    {
      path: 'deliveryManageDetail',
      name: 'deliveryManageDetail',
      meta: {
        title: '发货详情',
        icon: 'fa fa-usd',
        keepAlive: false,
        beforeEnter: true,
      },
      hidden: false,
      component: () => import('@/views/supplier/orderManagement/deliveryManage/deliveryManageDetail'),
    },
    {
      path: 'returnManage',
      name: 'returnManage',
      meta: {
        title: '退货列表',
        icon: 'fa fa-usd',
        keepAlive: true,
        beforeEnter: true,
      },
      hidden: true,
      component: () => import('@/views/supplier/orderManagement/returnManage/returnManage'),
    },
    // {
    //     path: 'returnManageDetail',
    //     name: 'returnManageDetail',
    //     meta: {
    //         title: '退货详情',
    //         icon: 'fa fa-usd',
    //         keepAlive: false,
    //         beforeEnter: true,
    //     },
    //     hidden: false,
    //     component: () =>
    //         import ('@/views/supplier/orderManagement/returnManage/returnManageDetail'),
    // },
  ],
}
