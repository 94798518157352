module.exports = {
  language: {
    name: '中文',
    short: 'en_US'
  },
  logoName: 'Merchant',
  asin: 'Asin/SPU Input',
  selectAnchor: 'Select Anchor',
  selectBrand: 'Select Brand',
  chinaTime: 'BJT',
  localTime: 'Local Time',
  localData: 'Local Date',
  search: 'Search',
  reset: 'Reset',
  typeLive: 'Streaming',
  endLive: 'Live finished',
  notStartLive: 'Live soon',
  watchLive: 'Watch',
  selectShop: 'Select Shop',
  selectMonth: 'Select Month',
  brokerage: 'Commission',
  affirm: 'Confirm',
  confirm: 'Confirm',
  cancel: 'Cancel',
  confirmed: 'Confirmed',
  download: 'Download',
  particulars: 'Details',
  account: 'Account Balance',
  addShop: 'Product added in this live',
  popconfirmMessage: 'You can not change the amount after confirming, are you sure this is correct?',
  languMsg: 'Select Language',
  calendar: {
    numLives: 'Live Created This Month ',
    lived: 'Already Streamed ',
    session: 'Total Sessions ',
    sessioning: 'Already Streamed ',
    shopNums: 'Product Number '
  },
  setting: {
    crmSetting: 'System Setting',
    password: 'Password',
    languageType: 'Language',
    updatePassword: 'Change Password',
    save: 'Save',
    quitLogin: 'Log Out',
    quitMsg: 'Are you sure to log out?',
    platform: 'Select System',
    amazon: 'Amazon Merchant',
    wahool: 'Vendor',
    enter: 'Enter',
    serviceSystem: 'System',
    serviceSystem1: 'System',
    serviceSystem2: 'System'
  },
  paswordSet: {
    oldInputMsg: 'Your New Password',
    newInputMsg: 'Confirm New Password',
    verifyOldMsg: 'Old Password',
    verifyNewMsg: 'Confirm New Password',
    oldNewOvifyMsg: 'Two Passwords Don’t Match'
  },
  login: {
    usernameLabel: 'Username',
    passwordLabel: 'Password',
    userNamePla: `Test{'@'}****company name`,
    userNamePlas: 'Please enter the username',
    passwordPla: 'Please enter the password',
    login: 'Log In',
    amazonLogin: 'Log in and Authorize',
    amazonLoginTitle: 'Connect With Your Amazon Store',
    ConfirmBinding: 'Confirm Authorize',
    logged: 'Logged in account',
    logInOther: 'Switch Accounts',
    selectStoreTitle: 'Select the store you want to authorize',
    selectedText: 'Selected',
    selectedNum: 'Stores',
    modalCancel: 'Cancel',
    modalConfirm: 'Confirm'
  },
  tablement: {
    month: 'Month',
    settlement: 'Settlement Interval',
    mentType: 'Settlement Type',
    shopName: 'Shop',
    sellNums: 'Sales Pieces',
    sellPrice: 'Sales Amount',
    returnNums: 'Return Pieces',
    returnPrice: 'Refund Amount',
    settlementPrice: 'Settlement Price',
    operate: 'Operate'
  },
  detTab1: {
    merchant: 'Merchant',
    shop: 'Shop',
    localData: 'Local Date',
    localTime: 'Local Time',
    livelink: 'Live Link',
    pit: 'Spot',
    pitPrice: 'Spot Fee',
    brand: 'Brand/ASIN',
    clickrate: 'Click Rate'
  },
  detTab2: {
    month: 'Month',
    dataTime: 'Date',
    shop: 'Shop',
    brand: 'Brand',
    commissionType: 'Commission%',
    commission: 'Commission'
  },
  chargeTable: {
    enterPrice: 'Transaction Date',
    accountType: 'Settlement Type',
    accountCycle: 'Settlement Interval',
    remitPrice: 'Remittance Amount($)',
    entryPrice: 'Received Amount($)',
    expendPrice: 'Paid Amount($)',
    subtotalPrice: 'Subtotal($)'
  },
  Amazon: {
    ConnectDate: 'Connect Date',
    authorizedTitle: 'Connect With Your Amazon Store',
    authorizedDescription: 'The sales orders generated by you in Wahool will be automatically synchronized to your Amazon Store to complete the order fulfillment',
    connectBtn: 'Connect',
    settings: 'Setting',
    Remove: 'Remove',
    ReAuthorize: 'ReAuthorize',
    EXPIRE: 'Your Amazon store authorization is expired. Please "Re-authorize" to ensure Wahool can continue creating order information for your store.',
    ABOUTTOEXPIRE: 'Your Amazon store authorization is expiring. Please "Re-authorize" to ensure Wahool can continue creating order information for your store.',
    stores: 'Store(s)',
    storeSetting: 'Shop setting',
    orderOpaction: 'Order fulfillment operation',
    orderShipMess: 'Order transportation mode',
    placeOrderRemaekMess: 'Please enter remarks',
    orderRemarkMess: 'Order note information',
    settingMess: 'Setting/saving is complete',
    placeSelectOpac: 'Please select the order fulfillment action',
    placeSelectShip: 'Please select a shipping method for your order',
    addNewStore: 'Add a new store',
    noSettingMess: 'Please Complete the store settings',
    renewMess: 'Authorization expired',
    authorizationExpired: 'Store authorization has expired',
    authorizationFailedErr: 'Store authorization failure',
    authorizationBinding: 'Amazon stores are already tied to merchants',
    authorizationRetry: 'Please remove the original merchant authorization and try again',
    closeBtn: 'Close'
  },
  route: {
    live: {
      live: 'Live',
      liveCalendar: 'Live Calendar',
      LiveList: 'Schedule'
    },
    financial: {
      financial: 'Finance',
      settlement: 'Settlement',
      charge: 'Charge',
      financialDetail: 'Settlement Details'
    },
    integrated: {
      integrated: 'integrated',
      Amazon: 'Amazon',
      Settings: 'Settings'
    }
  }
}
