/**
 * author: ღ
 * date: 2023/3/8
 */

'use strict';

/* eslint-disable */

import moment from 'moment'

// 而美国采用夏冬双时制，一个是夏令时，一个是冬令时（即标准时）。
// 夏令时比标准时早一个小时。
// 美国从2007年起修正时令，夏令时在每年三月的第二个星期日，冬令时在每年十一月的第一个星期日。亚利桑那州、印第安娜州、夏威夷州不使用夏时制。

/**
 *
 * @param _offset 可选 默认-8区(西八区)
 * @param appoint 可选 默认当前-8区(西八区)时间
 * @param _tzs 可选 默认当前时间'+00:00'时区 UTC时区
 * @returns {boolean}
 */
export default function (_offset = -8, appoint, _tzs = '+00:00') {
    let ttz = String(_offset).indexOf('-') === -1 && Math.abs(_offset) >= 10 ? `+${ Math.abs(_offset) }:00` : String(_offset).indexOf('-') === -1 && Math.abs(_offset) < 10 ? `+0${ Math.abs(_offset) }:00` : String(_offset).indexOf('-') !== -1 && Math.abs(_offset) >= 10 ? `-${ Math.abs(_offset) }:00` : `-0${ Math.abs(_offset) }:00`

    let st = moment.utc().utcOffset(_offset).year(), // 当前年份
        stMar1 = `${st}-03-01T00:00:00${ ttz }`,
        theWeekOfMarch1st = moment.parseZone(stMar1).utc().utcOffset(_offset).day(), // 当前年3月1号的星期
        mar2weekSevenDay = moment.parseZone(stMar1).utc().utcOffset(_offset).add(theWeekOfMarch1st === 0 ? 7 : 7 - theWeekOfMarch1st + 7, 'd').format(), // 当前年3月的第二个星期日 日期
        stNov1 = `${st}-11-01T00:00:00${ ttz }`,
        theWeekOfNovember1st = moment.parseZone(stNov1).utc().utcOffset(_offset).day(), // 当前年11月1号的星期
        Nov1weekSevenDay = moment.parseZone(stNov1).utc().utcOffset(_offset).add(theWeekOfNovember1st === 0 ? 0 : 7 - theWeekOfNovember1st, 'd').format(), // 当前年11月的第一个星期日 日期
        setTime = appoint ? moment.parseZone(appoint + _tzs).utc().utcOffset(_offset) : moment.utc().utcOffset(_offset).format()

    // console.group('%c ღ time', 'color: #389e0d')
    //     console.log('Date of the second Sunday in March', mar2weekSevenDay)
    //     console.log('Date of the first Sunday in November', Nov1weekSevenDay)
    //     console.log(appoint ? 'setTime' : 'Current time', setTime)
    //     console.log('moment.parseZone', moment.parseZone(setTime).utc().utcOffset(_offset).format())
    // console.groupEnd()

    // 返回
    return moment.parseZone(setTime).utc().utcOffset(_offset).isBetween(mar2weekSevenDay, Nov1weekSevenDay) // 检查一个 moment 是否在其他两个 moment 之间，可选地检查指定的单位刻度（分钟，小时，日期等）。 这个匹配是排他的。 前两个参数会被解析为 moment（如果尚未解析）。
}
