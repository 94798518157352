import { message } from 'ant-design-vue'
import { login, getUserInfo } from '@/api/login.js'
import { getAmazonListApi } from '@/api/financial.js'
import { changeI18n } from '@/language'
// import defaultSettings from '@/settings';
// const { platformNames } = defaultSettings;

const state = () => ({
  token: window.localStorage.getItem('token') || '',
  userInfo: (window.localStorage.getItem('userInfo') && JSON.parse(window.localStorage.getItem('userInfo'))) || {},
  userType: window.localStorage.getItem('userType') || ''
  // AmazonInfo: null // 亚马逊店铺信息
})

const mutations = {
  setToken(state, token) {
    window.localStorage.setItem('token', token)
    state.token = token
  },
  setUserName(state, name) {
    window.localStorage.setItem('userName', name)
    state.userInfo.name = name
  },
  setUserAvatar(state, avatar) {
    window.localStorage.setItem('userAvatar', avatar)
    state.userInfo.avatar = avatar
  },
  setUserInfo(state, data) {
    window.localStorage.setItem('userInfo', JSON.stringify(data))
    state.userInfo = data
  },
  setUserType(state, data) {
    console.log(data, 'datda-123123')
    state.userType = data
    window.localStorage.setItem('userType', data)
  },
  removeToken(state) {
    state.token = ''
    window.localStorage.removeItem('token')
  },
  removeUserType(state) {
    state.userType = ''
    window.localStorage.removeItem('userType')
  }
  // 设置store中的亚马逊店铺信息
  // setAmazonInfoMutations(state, data) {
  //   state.AmazonInfo = data
  // }
}

const actions = {
  login({ commit, dispatch }, data) {
    return login(data).then(async res => {
      if (res.code === 200) {
        // console.log('login login :>> ', res);
        commit('setToken', res.data)
        try {
          return await dispatch('getUserInfo')
          // dispatch('setUserType')
        } catch (error) {
          console.log('error :>> ', error)
        }
      } else {
        message.error(res.message)
      }
    })
  },
  getUserInfo({ commit }) {
    return getUserInfo().then(res => {
      if (res.code === 200) {
        // console.log('getUserInfo :>> ', res);
        // res.data.platformNames = ["Amazon", 'Wahool']
        commit('setUserInfo', res.data)
        commit('setUserName', res.data.account)
        return res.data
      } else {
        message.error(res.message)
      }
    })
  },
  // async resetBaseInfo({ state, dispatch }) {
  //   try {
  //     await dispatch('getUserInfo')
  //     dispatch('changeUserType', state.userType)
  //   } catch (error) {
  //     console.log('error :>> ', error);
  //   }
  // },
  setUserType({ dispatch, commit }, userType) {
    // commit('setUserType', res.data.userType || 'supplier') // business | supplier
    commit('setUserType', userType) // business | supplier
    changeI18n(userType)
    dispatch('getRoutes', userType)
  }
  // 设置亚马逊店铺信息
  // setAmazonInfoAction({ commit }) {
  //   return getAmazonListApi().then(res => {
  //     if (res.code === 200) {
  //       commit('setAmazonInfoMutations', res.data)
  //       return res.data
  //     } else {
  //       message.error(res.message)
  //     }
  //   })
  // }
  // changeUserType({ state, dispatch }, userType = state.userType) {
  //   changeI18n(userType)
  //   dispatch('getRoutes', userType)
  // },
}

export default {
  namespace: true,
  state,
  mutations,
  actions
}
