<template>
  <div>
    <router-view></router-view>
    <div v-if="$store.state.loading" id="loading-view" class="flexC">
      <a-spin tip="加载中..." size="large"></a-spin>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  setup() {},
};
</script>
<style>
@import './assets/css/app.css';
/* 骨架屏操作 */
.ant-skeleton-element .ant-skeleton-button {
  width: 100% !important;
  height: 100% !important;
}

.ant-pagination-item-link {
  background: #f6f7f9 !important;
  border-radius: 8px !important;
}
.ant-pagination-item {
  background: #f6f7f9 !important;
  border-radius: 8px !important;
}
.ant-pagination-item-active a {
  color: #ff6700 !important;
}
.ant-pagination-item a:hover {
  color: #ff6700 !important;
}
.ant-pagination-item-link {
  background: #f6f7f9 !important;
  border-radius: 8px !important;
}
p {
  margin: 0;
  padding: 0;
}
#loading-view {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.6);
}
/*.ant-table-tbody > tr > td {*/

/*  word-wrap: break-word;*/
/*  word-break: break-all;*/
/*}*/
/*.ant-table-fixed-header .ant-table-body-inner {*/
/*  overflow: hidden;*/
/*}*/
/*.ant-table-fixed tabel {*/
/*  height: 100% !important;*/
/*}*/
/*.ant-table-fixed-right, .ant-table-body-outer, .ant-table-fixed{*/
/*  height: 100% !important;*/
/*  padding-bottom: 2px;*/
/*}*/
/*.ant-table-row{*/
/*  min-height: 90px !important;*/
/*}*/
/*.ant-table-row-cell-break-word{*/
/*  padding: 10px !important;*/
/*}*/
.pagination_cont {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
