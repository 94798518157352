<template>
  <a-button type="primary" class="button">
    <slot/>
  </a-button>
</template>
<script>
export default {
  name: "button"
}
</script>

<style scoped lang="less">
.button{
  min-width: 100px;
}
</style>