<template>
  <div class="flexTTL boxS selectSlot">
    <label class="flexL"><slot name="title"/></label>
    <slot name="content"/>
  </div>
</template>

<script>
export default {
  name: "selectSlot",
}
</script>

<style scoped lang="less">
.selectSlot{
  width: 100%;
  float: left;
  label{
    margin-bottom: 5px;
    font-size: 14px;
  }
}
</style>