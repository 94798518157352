export default {
    path: '/live',
    name: 'live',
    orderBy: 0,
    meta: {
        title: '直播',
    },
    component: () =>
        import ('@/views/home'),
    hidden: true,
    redirect: '/live/calendar',
    children: [{
            path: 'calendar',
            name: 'liveCalendar',
            meta: {
                title: '排期日历',
                icon: 'fa fa-calendar',
                keepAlive: false,
                beforeEnter: true,
            },
            hidden: true,
            component: () =>
                import ('@/views/live/calendar/index'),
        },
        {
            path: 'calendar/list',
            name: 'LiveList',
            meta: {
                title: '排期列表',
                icon: 'fa fa-calendar',
                keepAlive: false,
                beforeEnter: true,
                back: true,
            },
            hidden: false,
            component: () =>
                import ('@/views/live/calendar/page/list'),
            children: [],
        },
    ],
};
