export default [{
        path: '/',
        name: 'home',
        redirect: '/', // 首页重置到home页，为预渲染重置到home
        meta: {
            title: '商家端首页',
        },
    },
    {
        path: '/',
        name: 'home',
        component: () =>
            import ('@/views/webSiteView/home/home.vue'),
        meta: {
            title: '商家端首页',
        },
    },
    {
        path: '/livestream',
        name: 'livestream',
        component: () =>
            import ('@/views/webSiteView/live/live.vue'),
        meta: {
            title: '红人直播服务',
        },
    },
    {
        path: '/partnerPage',
        name: 'partnerPage',
        component: () =>
            import ('@/views/webSiteView/partnerPage/partnerPage.vue'),
        meta: {
            title: '申请成为供货商',
        },
    },
    {
        path: '/livestreamPage',
        name: 'livestreamPage',
        component: () =>
            import ('@/views/webSiteView/liveServePath/liveServePath.vue'),
        meta: {
            title: '申请直播服务',
        },
    },
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () =>
            import ('@/views/webSiteView/aboutUs/aboutUs.vue'),
        meta: {
            title: '关于我们',
        },
    },
];