import request from '@/utils/request';

/**
 * 登录
 * http://yapi.wahool.com/project/11/interface/api/1916
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function login(data) {
    return request.POST('/api/account/login', data);
}

/**
 * 获取当前用户的信息
 * http://yapi.wahool.com/project/11/interface/api/1923
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function getUserInfo(data) {
    return request.GET('/api/account/info', data);
}
/**
 * 修改密码
 * http://yapi.wahool.com/project/11/interface/api/2000
 * @param data
 * @returns {Promise | Promise<any>}
 */
export function changePassword(data) {
    return request.POST('/api/account/change-password', data);
}

