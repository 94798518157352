import { createApp } from 'vue'
import App from './App.vue'
import routerIndex from './router/index'
import Antd from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css';
import store from './store'
import i18n from './language/index.js'
import module from './module'
const app = createApp(App)

// 全局自定义样式
import '@/assets/supplier/css/custom.less'
import '@/assets/css/fontFamily.css'
import 'highlight.js/styles/foundation.css'
import hljs from 'highlight.js'
import UTCTransfersTimeZone from '@/components/UTCTransfersTimeZone/index.vue'

app.directive('highlight', function (el) {
  hljs.configure({ useBR: true })
  let blocks = el.querySelectorAll('pre code')
  blocks.forEach(block => {
    hljs.highlightBlock(block)
  })
})
//
app.component('UTCTransfersTimeZone', UTCTransfersTimeZone)
app.use(routerIndex).use(Antd).use(store).use(i18n).use(module).mount('#app')
