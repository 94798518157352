const contexts = require.context('./modules', false, /\.js$/)
let supplierRoutes = []
contexts.keys().forEach((key) => {
  supplierRoutes[contexts(key).default.orderBy] = contexts(key).default
})

// supplierRoutes.unshift({
//     path: '/',
//     name: 'index',
//     component: () =>
//         import ('@/views/home'),
//     meta: { title: '首页', icon: 'fa fa-snowflake-o' },
//     redirect: '/supplier-system/orderManagement',
// })

supplierRoutes.push({
  path: '/printInvoice',
  name: 'printInvoice',
  component: () => import('@/views/supplier/orderManagement/deliveryManage/printInvoice.vue'),
  meta: { title: '发货清单', icon: 'fa fa-snowflake-o' },
})
supplierRoutes.push({
  path: '/printDeliver',
  name: 'printDeliver',
  component: () => import('@/views/supplier/orderManagement/deliveryManage/printDeliver.vue'),
  meta: { title: '发货清单', icon: 'fa fa-snowflake-o' },
})

export { supplierRoutes }
