import {
    createStore
} from 'vuex';
import getters from './getters'

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  modules[moduleName] = modulesFiles(modulePath).default
  return modules
}, {})

export default createStore({
    state: {
        loading: false,// 全局loading
        loadTimer: null,// 加载定时器
        // token: window.localStorage.getItem('token') ? window.localStorage.getItem('token') : '',
        // userInfo: {},
        myResource: [],
        searchLoading: false,
        searchLoadingTimer: null,
        keepAliveRoute: []
    },
    mutations: {
        openLoading(state, on_off) {
            if (!on_off) {
                state.loading = false;
                clearTimeout(state.loadTimer);
                state.loadTimer = null;
                return;
            }
            state.loadTimer = setTimeout(() => {
                state.loading = true;
                setTimeout(() => {
                    state.loading = false;
                }, 2000)
            }, 500)
        },
        searchLoading(state, on_off) {
            if (!on_off) {
                state.searchLoading = false;
                clearTimeout(state.searchLoadingTimer);
                state.searchLoadingTimer = null;
                return;
            }
            state.searchLoadingTimer = setTimeout(() => {
                state.searchLoading = true;
                setTimeout(() => {
                    state.searchLoading = false;
                }, 2000)
            }, 100)
        },
        changeSpinning(state, load) {
            state.spinning = load;
        },
        // setToken(state, token) {
        //     window.localStorage.setItem('token', token);
        //     state.token = token;
        // },
        // setUserName(state, name) {
        //     window.localStorage.setItem('userName', name);
        //     state.userInfo.name = name;
        // },
        // setUserAvatar(state, avatar) {
        //     window.localStorage.setItem('userAvatar', avatar);
        //     state.userInfo.avatar = avatar;
        // },
        setMyResource(state, myResource) {
            state.myResource = myResource;
        },
    },
    actions: {},
    getters,
    modules,
});
