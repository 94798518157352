export default {
  path: '/financial',
  name: 'financial',
  orderBy: 1,
  meta: {
    title: '财务'
  },
  component: () => import('@/views/home'),
  hidden: true,
  redirect: '/financial/settlement',
  children: [
    {
      path: 'settlement',
      name: 'settlement',
      meta: {
        title: '结算单',
        icon: 'fa fa-usd',
        keepAlive: false,
        beforeEnter: true
      },
      hidden: true,
      component: () => import('@/views/financial/settlement/index')
    },
    {
      path: 'charge',
      name: 'charge',
      meta: {
        title: '充值余额',
        icon: 'fa fa-file-text-o',
        keepAlive: false,
        beforeEnter: true
      },
      hidden: true,
      component: () => import('@/views/financial/charge/index')
    },
    {
      path: 'detail',
      name: 'financialDetail',
      meta: {
        title: '结算单明细',
        icon: 'fa fa-usd',
        keepAlive: false,
        beforeEnter: true
      },
      hidden: false,
      component: () => import('@/views/financial/detail/index')
    }
  ]
}
