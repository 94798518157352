import button from './components/button';
import selectSlot from './components/selectSlot';
// import homeButton from './components/homgButoon';
// import setting from './components/setting'

const module = {
    install(vue) {
        vue.component('w_select_slot', selectSlot);
        vue.component('w_button', button);
        // vue.component('w_home_button', homeButton);
        // vue.component('w_setting', setting);
    },
};

export default module;