import websitePath from './website'
export default [
  // {
  //     path: '/',
  //     name: 'index',
  //     component: () => import('@/views/home'),
  //     meta: {title: '首页', icon: 'fa fa-snowflake-o'},
  //     redirect: '/live',
  //     // children: [
  //     //     {
  //     //         path: 'welcome',
  //     //         component: () => import('@/views/homeIndex/index'),
  //     //         name: 'welcome',
  //     //         meta: {
  //     //             title: '欢迎登录',
  //     //             icon: 'fa fa-snowflake-o'
  //     //         }
  //     //     }
  //     // ]
  // },
  ...websitePath,
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录页面'
    },
    component: () => import('@/views/login')
  },
  {
    path: '/amazonWahoolLogin',
    name: 'amazonWahoolLogin',
    meta: {
      title: '亚马逊登录页面'
    },
    component: () => import('@/views/AmazonWahoolLogin')
  },
  {
    path: '/bindAmazon',
    name: 'bindAmazon',
    meta: {
      title: '亚马逊登录页面'
    },
    component: () => import('@/views/BindAmazon')
  },
  {
    path: '/transfer',
    name: 'transfer',
    meta: {
      title: 'Welcome!'
    },
    component: () => import('@/views/transfer')
  }
]
