export default {
    path: '/supplier-system/goodsManagement',
    name: 'goodsManagement',
    orderBy: 1,
    meta: {
        title: '商品中心',
    },
    component: () =>
        import ('@/views/home'),
    hidden: true,
    redirect: '/supplier-system/goodsManagement/goodsManage',
    children: [{
        path: 'goodsManage',
        name: 'goodsManage',
        meta: {
            title: '商品管理',
            icon: 'fa fa-cubes',
            keepAlive: false,
            beforeEnter: true,
        },
        hidden: true,
        component: () =>
            import ('@/views/supplier/goodsManagement/goodsManage/goodsManage'),
    }, ],
};
