const contexts = require.context('./modules', false, /\.js$/)
let businessRoutes = []
contexts.keys().forEach((key) => {
    businessRoutes[contexts(key).default.orderBy] = contexts(key).default
})

// businessRoutes.unshift({
//     path: '/live',
//     name: 'index',
//     component: () =>
//         import ('@/views/home'),
//     meta: { title: '首页', icon: 'fa fa-snowflake-o' },
//     redirect: '/live',
// })

export { businessRoutes }