import router, { businessRoutes, supplierRoutes, resetRouter } from '@/router'
import defaultSettings from '@/settings'
const { platformNames } = defaultSettings

const state = () => ({
  currentRoutes: [],
  keepAliveInclude: []
})

const mutations = {
  setCurrentRoutes(state, data) {
    state.currentRoutes = data
  },
  setKeepAliveInclude(state, data) {
    state.keepAliveInclude = data
  }
}

function getRouterKeepAlive(routes, result = new Set()) {
  routes.forEach(route => {
    if (route.meta && route.meta.keepAlive) {
      result.add(route.name)
    }
    if (route.children) {
      getRouterKeepAlive(route.children, result)
    }
  })
  return [...result]
}

const actions = {
  getRoutes({ commit, dispatch }, userType) {
    return new Promise(resolve => {
      // reset router
      resetRouter()

      const currentRoutes = userType == platformNames.Amazon ? businessRoutes : supplierRoutes
      // const currentRoutes = userType == 'business' ? supplierRoutes : businessRoutes
      console.error('getRoutes currentRoutes :>> ', currentRoutes)
      commit('setCurrentRoutes', currentRoutes)
      dispatch('getKeepAliveInclude', currentRoutes)

      const routesData = [...currentRoutes, { path: '/:pathMatch(.*)', redirect: '/404' }]
      routesData.forEach(route => {
        router.addRoute(route)
      })
      resolve()
    })
  },
  getKeepAliveInclude({ commit }, data) {
    // console.log('object :>> ', getRouterKeepAlive(data));
    commit('setKeepAliveInclude', getRouterKeepAlive(data))
  },
  routerRedirect({ state }) {
    console.log(state.currentRoutes, 'state.currentRoutes-----state.currentRoutes')
    router.push(state.currentRoutes[0].path)
  }
}

export default {
  namespace: true,
  state,
  mutations,
  actions
}
