import request from '@/utils/request'

//结算单列表
export function statement_page(data) {
  return request.GET('/api/report/business-statement/page', data)
}
//明细
export function statement_detail(data) {
  return request.GET('/api/report/business-statement/details', data)
}
// 获取店播场薪明细
export function getStatementLiveListApi(data) {
  return request.GET('/api/report/business-statement-live-fees/list', data)
}
// 下载excel
export function exportExcelApi(data, other) {
  return request.GET('/api/report/business-statement-live-fees/export-excel', data, { responseType: 'blob' }, other)
}
//获取remark
export function statement_info(data) {
  return request.GET('/api/report/business-statement/info', data)
}

//商家金额
export function balance(data) {
  return request.GET('/api/report/business-balance/amount', data)
}

//商家金额明细
export function balance_page(data) {
  return request.GET('/api/report/business-balance/page-list', data)
}

//导出结算单
export function statement_excel(data, other) {
  return request.GET('/api/report/business-statement/export-excel', data, { responseType: 'blob' }, other)
}

//确认结算单
export function statement_confirm(data) {
  return request.PUT('/api/report/business-statement/confirm', data)
}

//品牌
export function statement_brands(data) {
  return request.GET('/api/report/business-statement/brands', data)
}
/**
 * 授权绑定亚马逊
 */
export function authorization_amazon(data) {
  return request.POST('/api/amazon-account/authorization', data)
}
/**
 * 授权绑定亚马逊
 */
export function authorizationAmazonApi(data) {
  return request.POST('/supplier/amazon-account/authorization', data)
}
/**
 * 查询本人Amazon授权绑定记录
 */
export function getAmazonListApi(data) {
  return request.GET('/supplier/amazon-account/get', data)
}
/**
 * remove本人Amazon授权绑定记录
 */
export function removeAmazonApi(data) {
  return request.POST('/supplier/amazon-account/remove', data)
}
