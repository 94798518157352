import { createI18n } from 'vue-i18n'
import defaultSettings from '@/settings';
const { platformNames } = defaultSettings;

const businessMessages = {
    'zh_CN': require('./business/zh_CN'),
    'en_US': require('./business/en_US')
}

const supplierMessages = {
    'zh_CN': require('./supplier/zh_CN'),
    'en_US': require('./supplier/en_US')
}

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('languageSet') || 'zh_CN',
    messages: {
        ...businessMessages
    }
})

// console.log('i18n :>> ', i18n);

export function changeI18n(userType) {
    const data = userType == platformNames.Amazon ? businessMessages : supplierMessages
    i18n.global.setLocaleMessage('zh_CN', data['zh_CN'])
    i18n.global.setLocaleMessage('en_US', data['en_US'])
}

export const transformColumnsI18n = (data) => data.map((item) => ({ ...item, title: i18n.global.t(item.title) }));
// export function transformColumnsI18n() {
//     return this.map((item) => ({ ...item, title: t(item.title) }));
// }

export const filterInitialColumnsFn = (initialColumns, keys) => {
    initialColumns = JSON.parse(JSON.stringify(initialColumns))
    let result = []
    keys.forEach((key) => {
        if (initialColumns.find(it => it.key == key)) {
            result.push(initialColumns.find(it => it.key == key))
        }
    })
    return result
    // return keys.map((key) => {
    //     return initialColumns.find(it => it.key == key)
    // })
};
// export const filterInitialColumnsFn = (initialColumns, keys, includes = true) => initialColumns.filter((it) => {
//     return includes ? keys.includes(it.key) : !keys.includes(it.key)
// });
// export function filterInitialColumnsFn(keys) {
//     return this.filter((it) => keys.includes(it.key));
// }

export default i18n;
