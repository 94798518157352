import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import constantRoutes from './constantRoutes.js'
import { message } from 'ant-design-vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { businessRoutes } from './business'
// console.log('businessRoutes :>> ', businessRoutes);

import { supplierRoutes } from './supplier'
// console.log('supplierRoutes :>> ', supplierRoutes);

NProgress.configure({ showSpinner: false })
// import store from '../store/index';
// const contexts = require.context('./modules', false, /\.js$/);
// let asyncRoutes = [];
// contexts.keys().forEach((key) => {
//     asyncRoutes[contexts(key).default.orderBy] = contexts(key).default;
// });

// console.log(asyncRoutes);

const globalRouter = [
  {
    path: '/expand',
    name: 'expand',
    component: () => import('@/views/expand')
  },
  ...constantRoutes,
  // ...asyncRoutes,
  // ...businessRoutes,
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404')
  }
  // {
  //     path: '/:pathMatch(.*)',
  //     redirect: '/404',
  // },
]

function createRouterFn() {
  return createRouter({
    history: createWebHistory(),
    routes: globalRouter
  })
}
const router = createRouterFn()
const whiteList = ['/login', '/amazonWahoolLogin', '/expand']
router.beforeEach(async (to, from, next) => {
  console.log(to, 'to---123-----totototo')
  // addKeepAlive(to, from);
  // const token = localStorage.getItem('token');
  if (to.path == '/' || to.path == '/livestream' || to.path == '/partnerPage' || to.path == '/livestreamPage' || to.path == '/aboutUs') {
    document.body.scrollTop = 0 // firefox
    document.documentElement.scrollTop = 0 // safari
    window.pageYOffset = 0 // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
  }
  // 如果是亚马逊过来的，则将参数存到sessionStorage中
  if (to.query?.selling_partner_id) {
    sessionStorage.setItem('selling_partner_id', to.query?.selling_partner_id)
  }
  if (to.query?.amazon_callback_uri) {
    sessionStorage.setItem('amazon_callback_uri', to.query?.amazon_callback_uri)
  }
  if (to.query?.amazon_state) {
    sessionStorage.setItem('amazon_state', to.query?.amazon_state)
  }
  if (to.query?.spapi_oauth_code) {
    sessionStorage.setItem('spapi_oauth_code', to.query?.spapi_oauth_code)
  }
  NProgress.start()
  const token = store.getters.token
  if (token) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      const currentRoutes = store.getters.currentRoutes
      console.log('currentRoutes :>> ', currentRoutes)
      if ((currentRoutes && currentRoutes.length !== 0) || to.path === '/transfer') {
        next()
      } else {
        try {
          const userInfo = await store.dispatch('getUserInfo')
          console.error(store.getters.userType, userInfo.platformNames, 'store.getters.userType')
          if (store.getters.userType) {
            await store.dispatch('setUserType', store.getters.userType)
            // 如果是亚马逊，则请求亚马逊店铺信息接口，看是否过期，如果过期则菜单展示小红点
            // if (store.getters.userType === 'Amazon') {
            //   await store.dispatch('setAmazonInfoAction')
            // }
            if (to.path == '/' || to.path == '/livestream' || to.path == '/partnerPage' || to.path == '/livestreamPage' || to.path == '/aboutUs') {
              next({ ...to, replace: true })
            } else {
              store.dispatch('routerRedirect')
              next({ ...to, replace: true })
            }
            // next(`/transfer`)
          } else {
            if (userInfo.platformNames) {
              if (userInfo.platformNames.length == 1) {
                await store.dispatch('setUserType', userInfo.platformNames[0])
                // 如果是亚马逊，则请求亚马逊店铺信息接口，看是否过期，如果过期则菜单展示小红点
                // console.error(userInfo.platformNames[0], 'userInfo.platformNames[0]')
                // if (userInfo.platformNames[0] === 'Amazon') {
                //   await store.dispatch('setAmazonInfoAction')
                // }
                if (to.path == '/integrated/Settings') {
                  console.log('进来了')
                  next({ ...to, replace: true })
                } else {
                  store.dispatch('routerRedirect')
                  next()
                }
              } else {
                // router.push('/transfer')
                next(`/transfer`)
              }
            } else {
              await store.dispatch('setUserType')
              store.dispatch('routerRedirect')
              next()
            }
          }
          // await store.dispatch('setUserType')
          // store.dispatch('routerRedirect')
          // next(`/transfer`)
          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          // next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.commit('setToken', '')
          message.error(error || 'Has Error')
          // next(`/login?redirect=${to.path}`)
          next(`/login`)
          NProgress.done()
        }
      }
    }
  } else {
    // has no token
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()

      // next({ path: '/' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      // generate accessible routes map
      await store.commit('setToken', '')
      store.commit('setUserType', '')
      window.localStorage.removeItem('userType')
      window.localStorage.removeItem('userInfo')

      // other pages that do not have permission to access are redirected to the login page.
      // 如果是亚马逊商店过来的，则未登录跳亚马逊登录页
      if (sessionStorage.getItem('selling_partner_id')) {
        next('/amazonWahoolLogin')
      } else {
        next(`/login`)
      }
      NProgress.done()
    }
  }

  // if (to.name !== 'login' && to.name !== 'expand') {
  //     if (!token && to.meta.beforeEnter) {
  //         router.replace({
  //             name: 'login',
  //         });
  //     } else {
  //         next();
  //     }
  // } else {
  //     next();
  // }
})
// function addKeepAlive(to, from){
//     console.log(from.meta.keepAlive)
//     if(from.meta.keepAlive){
//         store.state.keepAliveRoute.push(from.name);
//     }
// }

router.afterEach(() => {
  setTimeout(() => {
    document.getElementById('admin-home-content') && (document.getElementById('admin-home-content').scrollTop = 0)
  }, 300)
  NProgress.done()
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouterFn()
  router.matcher = newRouter.matcher // reset router
}

export { constantRoutes, businessRoutes, supplierRoutes }
export default router
