import axios from 'axios'
import { message } from 'ant-design-vue'
import routerIndex from '../router/index'

let http = ''
if (process.env.NODE_ENV === 'development') {
  console.info('Request--开发环境')
  http = '/test'
} else {
  console.info('Request--生产环境')
  http = ''
}
const time_zone = 0 - parseInt(new Date().getTimezoneOffset() / 60)

export default {
  GET: (api, data, query, other) => {
    return new Promise((resolve, reject) => {
      let config = {
        params: data || {},
        headers: {
          Authentication: localStorage.getItem('token'),
          locale: time_zone
        }
      }
      if (query && query.responseType) {
        config['responseType'] = query.responseType
      }
      if (query && query.store) {
        config['headers']['store'] = query.store
      }
      axios
        .get(http + api, config)
        .then(response => {
          if (query && query.responseType) {
            let link = document.createElement('a')
            let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
            let objectUrl = window.URL.createObjectURL(blob)
            link.href = objectUrl
            let file_name
            if (other && other.fileName) {
              file_name = other.fileName
              response.data.fe_id = data.businessId
              response.data.fe_name = other.fileName
              resolve(response.data)
            } else {
              file_name = response.headers['content-disposition'].split(';')[1].split('=')[1]
              resolve(response.data)
            }
            console.log('file_name', file_name)
            link.download = file_name
            link.click()
            window.URL.revokeObjectURL(objectUrl)
            return
          }
          if (response.data.code !== 200) message.error(response.data.message)
          if (response.data.code === 599) {
            // 如果是亚马逊商店过来的，则登录过期跳亚马逊登录页
            if (sessionStorage.getItem('selling_partner_id')) {
              goAmazonLogin()
            } else {
              goLogin()
            }
          }
          resolve(response.data)
        })
        .catch(err => {
          console.log(
            '\n'
              .concat(` %c ${err}`, ' ')
              .concat(` %c /api${api} `)
              .concat(` %c ${JSON.stringify(data)} `, '\n', '\n'),
            'color: #fadfa3; background: #030307; padding:5px 0;',
            'background: #fadfa3; padding:5px 0;',
            'background: #1890ff; padding:5px;color:#ffffff;'
          )
          message.error('system error')
          reject(err)
        })
    })
  },
  POST: (api, data, form) => {
    return new Promise((resolve, reject) => {
      let config = {
        headers: {
          locale: time_zone
        }
      }
      if (api !== '/admin/account/login') {
        config['headers'] = config['headers'] || {}
        config['headers']['Authentication'] = localStorage.getItem('token')
      }
      if (form) {
        config['headers'] = config['headers'] || {}
        config['headers']['Content-Type'] = 'application/x-www-form-urlencoded'
      }
      axios
        .post(http + api, data, config)
        .then(response => {
          if (response.data.code !== 200) message.error(response.data.message)
          if (response.data.code === 599) goLogin()
          resolve(response.data)
        })
        .catch(err => {
          console.log(
            '\n'
              .concat(` %c ${err}`, ' ')
              .concat(` %c /api${api} `)
              .concat(` %c ${JSON.stringify(data)} `, '\n', '\n'),
            'color: #fadfa3; background: #030307; padding:5px 0;',
            'background: #fadfa3; padding:5px 0;',
            'background: #1890ff; padding:5px;color:#ffffff;'
          )
          message.error('system error')
          reject(err)
        })
    })
  },
  PUT: (api, data) => {
    return new Promise((resolve, reject) => {
      let config = {
        headers: {
          Authentication: localStorage.getItem('token'),
          locale: time_zone
        }
      }
      axios
        .put(http + api, data, config)
        .then(response => {
          if (response.data.code !== 200) message.error(response.data.message)
          resolve(response.data)
        })
        .catch(err => {
          console.log(
            '\n'
              .concat(` %c ${err}`, ' ')
              .concat(` %c /api${api} `)
              .concat(` %c ${JSON.stringify(data)} `, '\n', '\n'),
            'color: #fadfa3; background: #030307; padding:5px 0;',
            'background: #fadfa3; padding:5px 0;',
            'background: #1890ff; padding:5px;color:#ffffff;'
          )
          message.error('system error')
          reject(err)
        })
    })
  },
  DELETE: (api, data) => {
    let url = http + api
    return new Promise((resolve, reject) => {
      // axios.delete(http + api,  {data: (data || {}),headers: { Authentication: localStorage.getItem('token')}})
      axios({
        method: 'delete',
        url,
        data,
        headers: {
          Authentication: localStorage.getItem('token'),
          locale: time_zone
        }
      })
        .then(response => {
          if (response.data.code !== 200) message.error(response.data.message)
          resolve(response.data)
        })
        .catch(err => {
          console.log(
            '\n'
              .concat(` %c ${err}`, ' ')
              .concat(` %c /api${api} `)
              .concat(` %c ${JSON.stringify(data)} `, '\n', '\n'),
            'color: #fadfa3; background: #030307; padding:5px 0;',
            'background: #fadfa3; padding:5px 0;',
            'background: #1890ff; padding:5px;color:#ffffff;'
          )
          message.error('system error')
          reject(err)
        })
    })
  }
}

function goLogin() {
  routerIndex.push('/login')
  window.localStorage.removeItem('token')
}
/**
 * 亚马逊登录页
 */
function goAmazonLogin() {
  routerIndex.push('/amazonWahoolLogin')
  window.localStorage.removeItem('token')
}
let lock = false
axios.interceptors.response.use(
  function (response) {
    if (localStorage.getItem('indexmd5')) {
      if (localStorage.getItem('indexmd5') !== response.headers.indexmd5 && !lock) {
        lock = true
        window.localStorage.removeItem('indexmd5')
        window.location.reload(true)
      }
    } else {
      localStorage.setItem('indexmd5', response.headers.indexmd5)
    }
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)
